import React, { useState, useEffect } from 'react';
import { View, Text, Pressable, ScrollView, Image, FlatList } from 'react-native';
import { connect } from 'react-redux';
import DynamicFieldWithDescription from '../components/Profile/DynamicFieldwithDescription';
import FieldWithDescription from '../components/Profile/FieldWithDescription';
import FieldWithStaticPart from '../components/Profile/FieldWithStaticPart';
import CombinedStyles from './CombinedStyles';
import { crawlWebsite } from '../components/Profile/crawlWeb';
import * as Yup from 'yup';


const mapStateToProps = (state) => ({
    systemStore: state.systemStore,
});

const UserSetup = ({ systemStore }) => {
    const { colors } = systemStore;
    const [activeTab, setActiveTab] = useState('Profile');
    const [userData, setUserData] = useState(null);
    const [logoUrl, setLogoUrl] = useState('');
    const [faviconUrl, setFaviconUrl] = useState('');
    const [websiteUrl, setWebsiteUrl] = useState('');
    const [signupUrl, setSignupUrl] = useState('');
    const [aiQuestions, setAiQuestions] = useState(['']);
    const [aiDescription, setAiDescription] = useState('');
    const [products, setProducts] = useState(['']);
    const [gtmId, setGtmId] = useState('');
    const [leadScoring, setLeadScoring] = useState(['']);
    const [customUrl, setCustomUrl] = useState('');
    const [chats, setChats] = useState([]);
    const [selectedChat, setSelectedChat] = useState(null); 
    const [filter, setFilter] = useState('newest');
    const [error, setError] = useState(null);
    const [userId, setUserId] = useState(null);
    const styles = CombinedStyles(colors);

    useEffect(() => {
        const fetchUserId = async () => {
            try {
                const response = await fetch('/api/getUserId');
                const data = await response.json();
                if (data.userId) {
                    setUserId(data.userId);
                } else {
                    setError('User ID not found');
                }
            } catch (error) {
                setError(error.message);
                console.error('Error fetching user ID:', error);
            }
        };
    
        fetchUserId();
    }, []);

    // Fetch User Data after login
    useEffect(() => {
        const fetchUserData = async () => {
        try {
            const response = await fetch('/api/getUserData');
            const data = await response.json();
            if (data) {
                setUserData(data);
                setLogoUrl(data.logo_url || '');
                setWebsiteUrl(data.website_url || '');
                setSignupUrl(data.signup_url || '');
                setAiQuestions(data.ai_questions || []);
                setAiDescription(data.ai_description || '');
                setProducts(data.products || []);
                setGtmId(data.gtm_id || '');
                setLeadScoring(data.lead_scoring || []);
                setCustomUrl(data.custom_url || []);
            } else {
            setError('No user data found');
            }
        } catch (error) {
            setError('Error fetching user data');
            console.error(error);
        }
        };

        fetchUserData();
    }, []); // This will run once when the component mounts

    useEffect(() => {
        const fetchChats = async () => {
            try {
                const response = await fetch(`/api/chats?sort=${filter}`);
                const data = await response.json();

                if (data.chats) {
                    setChats(data.chats);
                    console.log("Fetched chats:", data.chats);
                } else {
                    console.error("Invalid chat data format");
                }
            } catch (error) {
                console.error("Error fetching chats:", error);
            }
        };

        fetchChats();
    }, [filter]);

    // PROFILE
    const addProduct = () => setProducts([...products, '']);
    const addQuestion = () => setAiQuestions([...aiQuestions, '']);
    const addLeadScoring = () => setLeadScoring([...leadScoring, '']);

    const deleteItem = (index, setState, state) => {
        const newState = state.filter((_, i) => i !== index);
        setState(newState);
    };

    const [isHovered, setIsHovered] = useState(false);


    const userSetupSchema = Yup.object().shape({
        logoUrl: Yup.string().url('Enter a valid URL for the logo.').required('Logo URL is required'),
        faviconUrl: Yup.string().url('Enter a valid URL for the favicon.').required('Favicon URL is required'),
        websiteUrl: Yup.string().url('Enter a valid URL for the website.').required('Website URL is required'),
        signupUrl: Yup.string().url('Enter a valid URL for signup.').required('Signup URL is required'),
        aiQuestions: Yup.array().of(Yup.string().required('Each AI question cannot be empty')),
        aiDescription: Yup.string().required('AI description is required'),
        products: Yup.array().of(Yup.string().required('Each product description cannot be empty')),
        gtmId: Yup.string().matches(/^GTM-\w+$/, 'Enter a valid Google Tag Manager ID (e.g., GTM-XXXXXX)'),
        leadScoring: Yup.array().of(Yup.string().required('Each lead scoring criteria cannot be empty')),
        customUrl: Yup.string()
            .matches(/^[a-zA-Z0-9_-]+$/, 'Custom URL can only contain letters, numbers, underscores, and hyphens.')
            .max(255, 'Custom URL must be at most 255 characters long.')
            .required('Custom URL is required.'),
    });

    const handleSave = async () => {

        const dataToSave = {
            userId,
            logoUrl,
            faviconUrl,
            websiteUrl,
            signupUrl,
            aiQuestions,
            aiDescription,
            products,
            gtmId,
            leadScoring,
            customUrl,
        };
          
        // Validate data entered using Yup
        try {   
            // Check if custom URL already exists before saving
            const urlCheckResponse = await fetch('/api/checkCustomUrl', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ customUrl, userId }),
            });
    
            if (!urlCheckResponse.ok) {
                const urlError = await urlCheckResponse.json();
                alert(urlError.error);  // Show an alert if URL exists
                return;
            }

            await userSetupSchema.validate(dataToSave, { abortEarly: false }); // abortEarly: false collects all errors
          
            // If valid, proceed with crawling and saving
            const crawledData = await crawlWebsite(websiteUrl);
            const combinedData = { ...dataToSave, crawledData };
          
            // Make API request to save data
            const response = await fetch('/api/saveUserData', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(combinedData),
            });
          
            if (response.ok) {
                const result = await response.text();
                console.log(result); // Handle success
            } else {
                const errorText = await response.text();
                console.error('Error saving data:', errorText);
            }
            } catch (validationError) {
              if (validationError.name === 'ValidationError') {
                validationError.inner.forEach(err => {
                  console.error(err.message); // Handle each validation error (show on UI as needed)
                });
            } else {
                console.error("Error while crawling or saving:", validationError);
            }
        }
    };
    
    // CHAT


    const renderChatItem = ({ item }) => {
        if (!item || item.length === 0) return null;

        const latestChat = item[0];
        const timestamp = new Date(latestChat.created_at).toLocaleString();

        return (
        <Pressable
            style={{
            marginBottom: 16,
            padding: 10,
            backgroundColor: '#fff',
            borderRadius: 8,
            borderWidth: 1,
            borderColor: colors.secondary
            }}
            onPress={() => setSelectedChat(item)}
        >
            <Text style={{ fontSize: 16, color: colors.primary }}>
            Overall Score: {latestChat.overall_score !== null ? latestChat.overall_score : 'No score'}
            </Text>
            <Text style={{ fontSize: 14, color: colors.secondary }}>
            Session ID: {latestChat.session_id}
            </Text>
            <Text style={{ fontSize: 14, color: colors.secondary }}>
            Timestamp: {timestamp}
            </Text>
        </Pressable>
        );
    };
    
    const renderSelectedChat = () => {
        if (!selectedChat || !selectedChat.length || selectedChat.length === 0) {
        return <Text style={{ color: colors.secondary, fontSize: 14 }}>No detailed chat available.</Text>;
        }
    
        return (
        <View style={{ padding: 16 }}>
            <Pressable
            style={{
                alignSelf: 'flex-end',
                paddingVertical: 8,
                paddingHorizontal: 12,
                backgroundColor: colors.primary,
                borderRadius: 20,
            }}
            onPress={() => setSelectedChat(null)}
            >
            <Text style={{ color: '#fff' }}>X</Text>
            </Pressable>
    
            <Text style={{ color: colors.primary, fontSize: 18, marginBottom: 12 }}>
            Overall Score: {selectedChat[0]?.overall_score || 'No score available'}
            </Text>
    
            {[...selectedChat]
                .sort((a, b) => a.question_order - b.question_order)
                .map((chat, index) => (
                <View key={index} style={{ marginBottom: 8 }}>
                    <Text style={{ color: colors.primary, fontSize: 16, marginBottom: 10 }}>
                    Q: {chat.question}
                    </Text>
                    <Text style={{ color: '#2F4858', fontSize: 16, marginBottom: 10 }}>
                    A: {chat.answer}
                    </Text>
                    <Text style={{ color: colors.primary, fontSize: 14, marginBottom: 10 }}>
                    Score: {chat.score}
                    </Text>
                    <Text style={{ color: '#2F4858', fontSize: 14, marginBottom: 10 }}>
                    Evaluation: {chat.evaluation || 'No evaluation available'}
                    </Text>
                </View>
                ))}
            </View>
        );
    };
      
    if (!userData) {
        console.log("No userData detected:", userData);
        return <div>Loading...</div>;
    }

    return (
        <View style={{ position: 'absolute', width: '100%', height: '100%' }}>
        {/* Header Section */}
        <View style={styles.header}>
            <Image source={systemStore.source.logo} style={styles.headerLogo} /> 
        </View> 

        {/* Tabs for Profile and Chats */}
        <View style={{ flexDirection: 'row', justifyContent: 'space-around', marginBottom: 16 }}>
            <Pressable
                style={{ paddingVertical: 12, paddingHorizontal: 24, borderRadius: 24 }}
                onPress={() => setActiveTab('Profile')}
            >
                <Text style={{ color: activeTab === 'Profile' ? colors.primary : colors.secondary }}>Profile</Text>
                {activeTab === 'Profile' && (
                    <View style={{ height: 2, backgroundColor: colors.primary, marginTop: 4 }} /> // Underline
                )}
            </Pressable>
            <Pressable
                style={{ paddingVertical: 12, paddingHorizontal: 24, borderRadius: 24 }}
                onPress={() => setActiveTab('Chats')}
            >
                <Text style={{ color: activeTab === 'Chats' ? colors.primary : colors.secondary }}>Chats</Text>
                {activeTab === 'Chats' && (
                    <View style={{ height: 2, backgroundColor: colors.primary, marginTop: 4 }} /> // Underline
                )}
            </Pressable>
        </View>

        {/* Content Based on Active Tab */}
        <ScrollView style={{ flex: 1, paddingHorizontal: 32 }}>
        {/* Profile Tab Content */}
            {activeTab === 'Profile' ? (
            <View>
                {/* Logo URL*/}
                <FieldWithDescription
                label="Logo URL"
                description="Enter the URL for your company logo (e.g., .png or .jpg)."
                value={logoUrl}
                onChangeText={setLogoUrl}
                placeholder="Enter logo URL"
                colors={colors}
                />
                {/* Favicon URL*/}
                <FieldWithDescription
                label="Favicon URL"
                description="Enter the URL for your website's favicon (e.g., .ico or .png)."
                value={faviconUrl}
                onChangeText={setFaviconUrl}
                placeholder="Enter favicon URL"
                colors={colors}
                />
                {/* Website URL*/}
                <FieldWithDescription
                label="Website URL"
                description="Provide the URL of your website to gather product info."
                value={websiteUrl}
                onChangeText={setWebsiteUrl}
                placeholder="Enter website URL"
                colors={colors}
                />
                {/* SignUp URL*/}
                <FieldWithDescription
                label="Signup URL"
                description="Enter the URL where users can sign up for your service."
                value={signupUrl}
                onChangeText={setSignupUrl}
                placeholder="Enter signup URL"
                colors={colors}
                />

                {/* GTM ID */}
                <FieldWithDescription
                label="Google Tag Manager ID"
                description="Input your Google Tag Manager ID (e.g., GTM-XXXXXX)."
                value={gtmId}
                onChangeText={setGtmId}
                placeholder="Enter GTM ID"
                colors={colors}
                />

                {/* AI Description */}
                <FieldWithDescription
                label="AI Description"
                description="Describe how you want the AI to respond."
                value={aiDescription}
                onChangeText={setAiDescription}
                placeholder="Enter AI description"
                colors={colors}
                />
                    
                {/* AI Questions*/}
                <DynamicFieldWithDescription
                    label="AI Questions"
                    description="List the questions the AI should be able to answer."
                    data={aiQuestions}
                    onChange={setAiQuestions}
                    placeholder="Enter a question"
                    colors={colors}
                    addItem={addQuestion}
                    deleteItem={(index) => deleteItem(index, setAiQuestions, aiQuestions)}
                />

                {/* Products */}
                <DynamicFieldWithDescription
                    label="Products"
                    description="Enter the names or short descriptions of your products."
                    data={products}
                    onChange={setProducts}
                    placeholder="Enter a product"
                    colors={colors}
                    addItem={addProduct}
                    deleteItem={(index) => deleteItem(index, setProducts, products)}
                />

                {/* Lead Scoring */}
                <DynamicFieldWithDescription
                    label="Lead Scoring"
                    description="Explain how you rate potential customers to find the best ones interested in your products."
                    data={leadScoring}
                    onChange={setLeadScoring}
                    placeholder="Enter scoring criteria"
                    colors={colors}
                    addItem={addLeadScoring}
                    deleteItem={(index) => deleteItem(index, setLeadScoring, leadScoring)}
                />

                {/* Custom URL */}
                <FieldWithStaticPart
                label="Custom URL"
                description="Enter a custom URL for your personalized business chatbot page."
                value={customUrl} 
                onChangeText={setCustomUrl}
                placeholder="Enter custom URL"
                colors={colors}
                />

                <Pressable 
                    onPress={handleSave}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    style={[
                    styles.saveButton, 
                    { backgroundColor: isHovered ? colors.secondary : colors.primary }
                    ]}
                >
                    <Text style={styles.saveButtonText}>Save</Text>
                </Pressable>

            </View>
            
            ) : (
                // Chats tab content
                <View style={{ flexDirection: 'row', flex: 1 }}>
                    {/* Left: Chat previews */}
                    <ScrollView style={{ flex: 1, marginRight: 16 }}>
                        {/* Filters */}
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 16 }}>
                            <Pressable
                                style={{ padding: 8, backgroundColor: filter === 'newest' ? colors.primary : colors.secondary, borderRadius: 8 }}
                                onPress={() => setFilter('newest')}
                            >
                                <Text style={{ color: "#fff" }}>Newest</Text>
                            </Pressable>
                            <Pressable
                                style={{ padding: 8, backgroundColor: filter === 'highest' ? colors.primary : colors.secondary, borderRadius: 8 }}
                                onPress={() => setFilter('highest')}
                            >
                                <Text style={{ color: "#fff" }}>Highest Score</Text>
                            </Pressable>
                        </View>
                        <FlatList
                            data={chats}
                            keyExtractor={(item) => item.sessionId}
                            renderItem={renderChatItem}
                            ListEmptyComponent={<Text>No chats available</Text>}
                        />
                    </ScrollView>

                    {/* Right: Detailed view of the selected chat */}
                    <View style={{ flex: 2, padding: 16, backgroundColor: colors.tertiary, borderRadius: 8 }}>
                        {selectedChat ? (
                            renderSelectedChat()
                        ) : (
                            <Text style={{ color: colors.secondary, fontSize: 14 }}>Select a chat to see the full conversation.</Text>
                        )}
                    </View>
                </View>
            )}
        </ScrollView>
    </View>
    );
};
export default connect(mapStateToProps)(UserSetup);
