export const pushToDataLayer = (event) => {
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer.push(event);
    }
  };
  
  export const trackChatEvent = ({
    eventName,
    question,
    answer,
    score,
    sessionId,
    overallScore
  }) => {
    pushToDataLayer({
      event: eventName,
      chat: {
        question,
        answer,
        score,
        sessionId,
        overallScore
      }
    });
  };