import React from 'react';
import { View, Text, TextInput, StyleSheet } from 'react-native';

const FieldWithStaticPart = ({
  label,
  description,
  value,
  onChangeText,
  placeholder,
  colors
}) => {
  const staticPart = "https://www.sales.shop/";

  const handleChange = (text) => {
    onChangeText(text);
  };

  return (
    <View style={styles.container}>
      {/* Title */}
      <Text style={[styles.title, { color: colors.primary }]}>{label}</Text>
      {/* Description */}
      <Text style={[styles.description, { color: colors.secondary }]}>{description}</Text>
      {/* Combined Input Field */}
      <View style={styles.inputContainer}>
        <Text style={styles.staticPart}>{staticPart}</Text>
        <TextInput
          value={value}
          onChangeText={handleChange}
          style={[styles.textInput, { flex: 1 }]}
          placeholder={placeholder}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: 20,
  },
  title: {
    fontSize: 18,
    paddingBottom: 5,
  },
  description: {
    fontSize: 14,
    paddingBottom: 10,
  },
  inputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderColor: '#ccc',
    borderWidth: 1,
    borderRadius: 8,
    paddingHorizontal: 8,
  },
  staticPart: {
    fontSize: 14,
    marginRight: 5,
  },
  textInput: {
    height: 40,
  },
});

export default FieldWithStaticPart;
