const TestTheme = {
  source: {
    id: 'test',
    title: 'Test Theme',
    logo: require(`../../assets/images/few.png`),
    favicon: require(`../../assets/images/few.ico`),
    gtmTag: '',
    precursors: [],
  },
  colors: {
    primary: 'red',
    secondary: 'blue',
    tertiary: 'yellow',
    // tone: '#ffcccb',
    // highlight: '#add8e6',
  },
  fonts: {
    primary: 'white',
    secondary: 'white',
    tertiary: '',
  },
}

const FewTheme = {
  source: {
    id: 'few',
    title: 'For Every Woman',
    logo: require(`../../assets/images/few.png`),
    favicon: require(`../../assets/images/few.ico`),
    gtmTag: '',
    precursors: [],
  },
  colors: {
    primary: '#f4786b',
    secondary: '#f3f4f7',
    tertiary: '#dcf1de',
    // tone: '#ffcccb',
    // highlight: '#add8e6',
  },
  fonts: {
    primary: 'white',
    secondary: 'black',
    tertiary: '',
  },
}

const LifelineTheme = {
  source: {
    id: 'lifeline',
    title: 'Lifeline Adoption Interview',
    logo: require(`../../assets/images/lifeline.png`),
    favicon: require(`../../assets/images/lifeline.ico`),
    gtmTag: 'GTM-PLLQ8TC',
    precursors: [
      { message: 'Can you share some of the core values you prioritize in your family life?', },
      { message: 'How do you typically spend your weekends and holidays?', },
      { message: 'What community activities or groups are you involved in?', },
      { message: 'How do you approach teaching moral values to children?', },
      { message: 'Can you describe a time when you had to navigate a difficult decision with your family? What guided you?', },
      { message: 'What role does faith or spirituality play in your family life?', },
      { message: 'How do you encourage and support personal growth in your family members?', },
      { message: 'Can you share a family tradition that is meaningful to you?', },
      { message: 'How do you handle conflicts or disagreements in your household?', },
      { message: 'What qualities do you believe are important for a child\'s development?', },
      { message: 'How do you approach conversations about purpose and meaning with your children?', },
      { message: 'Can you tell me about a book or resource that has influenced your parenting style?', },
      { message: 'How do you prioritize family time and activities?', },
      { message: 'What are some ways you volunteer or give back to the community?', },
      { message: 'How do you celebrate significant milestones or achievements in your family?', },
      { message: 'How do you support and encourage your children\'s education and learning?', },
      { message: 'Can you share an example of how you teach compassion and empathy to your children?', },
      { message: 'What are your thoughts on the importance of community and fellowship in raising a child?', },
      { message: 'How do you handle and teach financial stewardship in your household?', },
      { message: 'Can you describe a time when you had to rely on faith or a higher power to get through a challenging situation?', },
      { message: 'Thank you!', },
    ],
  },
  colors: {
    primary: '#236995',
    secondary: '#50545a',
    tertiary: '#ffffff',
    // tertiary: '#2f4b63',
    // tone: '#ffcccb',
    // highlight: '#add8e6',
  },
  fonts: {
    primary: 'white',
    secondary: 'white',
    tertiary: '',
  },
}

const GrantWriterTheme = {
  source: {
    id: 'grantwriter',
    title: 'Grant Writer',
    logo: require(`../../assets/images/grantwriter.png`),
    favicon: require(`../../assets/images/grantwriter.ico`),
    gtmTag: '',
    precursors: [
      { message: 'Enter the website for your organization so I understand your mission.', },
      { message: 'Give a short description of the grant you are applying for.', },
      { message: 'What is the impact of this grant and what are the short and long term benefits?', },
      { message: 'What is your budget?', },
      { message: 'What is your timeline?', },
      { message: 'Done!', },
    ],
  },
  colors: {
    primary: '#5c89b5',
    secondary: '#f19c91',
    tertiary: '#ffffff',
    // tertiary: '#2f4b63',
    // tone: '#ffcccb',
    // highlight: '#add8e6',
  },
  fonts: {
    primary: 'white',
    secondary: 'white',
    tertiary: '',
  },
}

const theme = GrantWriterTheme

const INITIAL_STATE = {
  source: theme.source,
  colors: {
    // Default colors
    primary: '#236995',
    secondary: '#50545a',
    tertiary: '#ffffff',
    ...theme.colors,
  },
  fonts: {
    primary: 'white',
    secondary: 'white',
    tertiary: '',
    ...theme.fonts,
    black: 'black',
    white: 'white',
    red: 'red',
    sm: 10,
    md: 14,
    lg: 18,
    xl: 24,
  },
  window: [0, 0],
  mobile: false,
}

export const SystemActions = {
  SetSystemSource: 'SET_SYSTEM_SOURCE',
  SetWindow: 'SET_WINDOW',
}

const systemStore = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SystemActions.SetSystemSource:
      return {
        ...state,
        source: {
          ...state.source,
          ...action.payload, // Merge the incoming source with the existing one
        },
        colors: action.payload.colors || state.colors, // Fallback to existing colors if not provided
      }
    case SystemActions.SetWindow:
      return {
        ...state,
        window: action.payload,
        mobile: action.payload[0] < 768,
      }
    default:
      return state
  }
}

export default systemStore
