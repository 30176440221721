let env
env = 'prod'
//env = 'dev'

export const baseUrl = (env === 'prod')
  // ? 'https://chat.lifeline.staging.sukow.org'
  ? 'https://www.sales.shop'  // Production URL
  : 'http://localhost:8080'   // Development URL

export const prompt = async (chatId, organizationId, message) => {
  try {
    const response = await fetch(`${baseUrl}/prompt`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', },
      body: JSON.stringify({ chatId, organizationId, message, }),
    })
    const data = await response.text()
    return data
  } catch(e) {
    console.log(e)
  }
}

export const evaluate = async (chatId, organizationId, message, precursor) => {
  try {
    const response = await fetch(`${baseUrl}/evaluate`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', },
      body: JSON.stringify({ chatId, organizationId, message, precursor, }),
    })
    const data = await response.text()
    return data
  } catch(e) {
    console.log(e)
  }
}
