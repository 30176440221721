import { SystemActions } from '../reducers/SystemReducer'

export function setSystemSource(payload) {
  return {
    type: SystemActions.SetSystemSource,
    payload: payload,
  }
}

export function setWindow(payload) {
  return {
    type: SystemActions.SetWindow,
    payload: payload,
  }
}
